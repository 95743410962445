import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Map',
        name: 'Map',
        component: () => import(/* webpackChunkName: "about" */ '../views/Map.vue')
    },
    {
        path: '/Report',
        name: 'Report',
        component: () => import(/* webpackChunkName: "about" */ '../views/Report.vue')
    },
    {
        path: "/recuperarSenha",
        name: 'RecuperarSenha',
        component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue')
    },
    {
        path: "/Vehicle",
        name: 'Vehicles',
        component: () => import(/* webpackChunkName: "about" */ '../views/Vehicle.vue')
    },
    {
        path: "/Config",
        name: 'Config',
        component: () => import(/* webpackChunkName: "about" */ '../views/Config.vue')
    },
    {
      path: "/myAccount",
      name: 'myAccount',
      component: () => import(/* webpackChunkName: "about" */ '../views/Account.vue')
      
    },
    {
      path: "/Events",
      name: 'Events',
      component: () => import(/* webpackChunkName: "about" */ '../views/Events.vue')
      
    }
]

const router = new VueRouter({
    routes
})

export default router
