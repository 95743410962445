import { create } from 'apisauce';
import router from '@/router/index.js';

const apisauce = (token) =>{
    const api = token ? create({
        baseURL: 'https://empa-ba.americatrack.com/api/',
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) : create({
        baseURL: 'https://empa-ba.americatrack.com/api/'
    });
    api.addResponseTransform((r)=>{
        if(!r.ok){
            if(r.data && r.data.error == "Token invalid"){
                localStorage.removeItem("usrId");
                router.push("/");
            }else if(r.status == 500)
                apisauce(localStorage.getItem("token")).post('endpoint/devices_erros',
                {
                    erro: JSON.stringify(r.data),
                    idusuario: localStorage.getItem("usrId"),
                    idcliente: localStorage.getItem("idcliente"),
                    endpoint: r.config.baseURL
                });
        } 
    });
    return api;
}; 

export default apisauce;

//devices_erros 