<template>
    <v-app>
        <v-main id="main-container">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </v-main>
    </v-app>
</template>

<style lang="scss">
:root {
    --banner-title-height: 280px;
}

html body {
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
}

.v-application {
    overflow: hidden;
}

.v-main {
    padding: 0px !important;
}

.v-banner__wrapper {
    padding-bottom: 0px !important;
}

.home {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#div-content {
    height: 100%;
    overflow: hidden;
}

#divTitle {
    padding-top: 130px;
}

#divList {
    height: calc(100vh - var(--banner-title-height));
    overflow-y: auto;
    /* box-shadow: rgba(255, 255, 255, 0.5) 0px 10px 20px -6px inset; */
}

.list {
    /* height: 100%; */
    /* margin: 0; */
    /* padding: 5px; */
    /* border-radius: 5px !important; */
    background-color: #cdcccc !important;
}

.list-item {
    width: auto;
    margin: 0 0 5px;
    padding: 5px;
    border-radius: 5px;
    background: #fcfcfc;
    box-shadow: #0000003d 0px 5px 8px;

    .title {
        font-size: x-large;
        color: darkblue;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: bold;
    }

    .value {
        font-size: small;
        color: black;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .value1 {
        font-size: small;
        color: black;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        /* font-weight: bold; */
    }

    .value2 {
        font-size: small;
        color: black;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .label {
        font-size: small;
        color: gray;
        font-family: 'Courier New', Courier, monospace;
    }
}

.banner-title {
    padding-top: 130px;
    padding-bottom: 5px !important;
}

.v-image__image--cover {
    background-size: contain !important;
}
</style>

<script>
import api from '@/plugins/api.js';
import router from '@/router';
import configApp from './config.app.json';

export default {
    name: 'App',

    data: () => ({
        version: "0.0.0",
        customization: {},
    }),

    methods: {
        findGetParameter(parameterName) {
            var result = null,
                tmp = [];
            location.search
                .substr(1)
                .split("&")
                .forEach(function (item) {
                    tmp = item.split("=");
                    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
                });
            return result;
        },
        applyTheme(custom) {
            this.$vuetify.theme.dark = custom.dark;
            this.$vuetify.theme.themes[custom.dark ? 'dark' : 'light'].primary = custom.mainBtnColor;
            this.$vuetify.theme.themes[custom.dark ? 'dark' : 'light'].secondary = custom.topBarColor;
            this.$vuetify.theme.themes[custom.dark ? 'dark' : 'light'].info = custom.leftMenuColor;
            this.customization = custom;
        },
        logout() {
            localStorage.removeItem("token");
            localStorage.removeItem("usrId");
            localStorage.removeItem("user");
            router.push("/")
        },
        initMap() {
            const googleMaps = document.createElement("script");
            const mapsKey = "AIzaSyAxpTdCfd8SxVXcjov2--j2vJlf6c7HuqE";
            googleMaps.src = `https://maps.googleapis.com/maps/api/js?key=${mapsKey}&callback=afterMapInit`
            googleMaps.defer = true;
            googleMaps.async = true;

            // Attach your callback function to the `window` object
            window.afterMapInit = function () {
                // console.log(" --> Mapa inicializado!");
                window.mainApp.isGoogleMapsLoaded = true;
            };

            document.head.appendChild(googleMaps);

            const markerCluster = document.createElement("script");
            markerCluster.src = "https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js"
            document.head.appendChild(markerCluster);
        },
    },

    created() {
        this.version = configApp.appversion;
        window.mainApp = this;
        window.appType = configApp.apptype; // brasiltrack/americatrack
        if (window.appType) {
            api().get(`/setting/${window.appType}`).then(r => {
                const custom = r.data.json ? JSON.parse(r.data.json) : {};
                custom.dark = custom.dark ? custom.dark : false;
                custom.backgroundColor = custom.backgroundColor ? custom.backgroundColor : '#f2f2f2';
                custom.topBarColor = custom.topBarColor ? custom.topBarColor : '#1e3987';
                custom.leftMenuColor = custom.leftMenuColor ? custom.leftMenuColor : '#25386e';
                custom.topBarTextColor = custom.topBarTextColor ? custom.topBarTextColor : '#ffffff'; // white
                custom.mainBtnColor = custom.mainBtnColor ? custom.mainBtnColor : '#008000'; // green
                custom.logo = custom.logo ? custom.logo : "";
                custom.name = custom.name ? custom.name : 'Brasiltrack'

                this.applyTheme(custom);

                localStorage.setItem("system", window.appType);
                localStorage.setItem("customization", JSON.stringify(custom));
            })
        } else {
            if (localStorage.getItem("customization")) {
                const custom = JSON.parse(localStorage.getItem("customization"));
                this.applyTheme(custom);
            }
        }
        this.initMap();
    }
};
</script>
