<template>
    <div class="home" :style="'background-color: ' + customization.backgroundColor + ';'">
        <v-alert class="alert" v-model="alert" dismissible color="error" icon="mdi-alert-circle"
            transition="slide-x-reverse-transition" dark>
            Erro ao encontrar sua conta no servidor!
        </v-alert>
        <v-container fluid fill-height>
            <v-row align="center" justify="center">
                <v-col class="text-center">
                    <v-card class="mx-auto" max-width="500" style="border-radius: 20px" elevation="8">
                        <v-img class="white--text align-end" height="125px" :src="customization.logo"
                            style="background-color: transparent; border-start-start-radius: 20px" />
                        <v-card-subtitle>Seja bem vindo(a)!<br /> Informe seus dados para fazer o
                            Login.</v-card-subtitle>
                        <v-row align="center" justify="center">
                            <v-col cols="10" md="4">
                                <v-text-field label="Conta" :rules="fieldRules" v-model="inputConta" id="conta_field"
                                    @keydown.enter="logar" outlined></v-text-field>
                            </v-col>
                            <v-col cols="10" md="6" class="forms_input_mobile_index">
                                <v-text-field label="Usuário" :rules="fieldRules" v-model="inputUsuario" id="usuario_field"
                                    @keydown.enter="logar" outlined></v-text-field>
                            </v-col>
                            <v-col cols="10" class="forms_input_mobile_index2">
                                <v-text-field label="Senha" type="password" :rules="fieldRules" v-model="inputSenha"
                                    id="senha_field" @keydown.enter="logar" outlined></v-text-field>
                                <v-checkbox id="lembrar_field" v-model="inputLembrar"
                                    label="Lembrar dados de login"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-btn text to="/recuperarSenha" color="secondary"><small>Esqueci minha senha</small></v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text @click="logar" color="primary">
                                Entrar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <div style="position: fixed; bottom: 15px; text-align: center; width: 100%; opacity: .5">
                Versão {{ version }}
            </div>
        </v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<style scoped>
.alert {
    position: fixed;
    right: 5px;
    bottom: 5px;
    max-width: 500px;
    width: calc(100% - 10px);
    z-index: 1000000;
}

.forms_input_mobile_index {
    transform: translateY(0px);
}

.forms_input_mobile_index2 {
    transform: translateY(-30px);
}

@media screen and (max-width: 960px) {
    .forms_input_mobile_index {
        transform: translateY(-25px)
    }

    .forms_input_mobile_index2 {
        transform: translateY(-55px);
    }
}
</style>

<script>
import api from '@/plugins/api.js'
import router from '@/router/index.js'

export default {
    name: 'Home',
    data: () => ({
        fieldRules: [
            r => (r && r.length > 0) || "Preencha esse campo."
        ],
        inputConta: null,
        inputUsuario: null,
        inputSenha: null,
        alert: false,
        inputLembrar: false,
        version: "",
        overlay: true,
        customization: {},
    }),
    methods: {
        logar() {
            if (!this.inputConta) return document.getElementById("conta_field").focus();
            if (!this.inputUsuario) return document.getElementById("usuario_field").focus();
            if (!this.inputSenha) return document.getElementById("senha_field").focus();

            api().post('/sessionBtx', {
                account: this.inputConta,
                login: this.inputUsuario,
                senha: this.inputSenha,
            }).then(r => {
                if (r.ok) {

                    // verificar o tipo de cliente, 
                    // Brasiltrack (parceria = null) ou Americatrack (parceria = qualquer outro valor)
                    if ((!r.data.user[0].parceria && window.appType == "brasiltrack") ||
                        (r.data.user[0].parceria && window.appType == "americatrack")) {

                        localStorage.setItem("token", r.data.token);
                        localStorage.setItem("usrId", r.data.user[0].usu_ID);
                        localStorage.setItem("user", JSON.stringify(r.data.user[0]));

                        if (this.inputLembrar) {
                            localStorage.setItem("contaSave", this.inputConta);
                            localStorage.setItem("usuarioSave", this.inputUsuario);
                        }

                        router.push('Map');
                    } else
                        this.alert = true;

                } else this.alert = true;
            })
        },
    },
    mounted() {
        const timerLoadLogo = setInterval(() => {
            if (localStorage.getItem("customization")) {
                clearInterval(timerLoadLogo);

                this.customization = JSON.parse(localStorage.getItem("customization"));
                this.overlay = false;
            }
        }, 500);

        this.version = window.mainApp.version;

        if (localStorage.getItem("usrId")) router.push('Map');

        if (localStorage.getItem("contaSave")) {
            this.inputConta = localStorage.getItem("contaSave");
            this.inputUsuario = localStorage.getItem("usuarioSave");
            this.inputLembrar = true;
        }
    }
}
</script>

